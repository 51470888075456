import AddIcon from '@mui/icons-material/Add';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderTopIcon from '@mui/icons-material/BorderTop';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LogoutIcon from '@mui/icons-material/Logout';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PauseIcon from '@mui/icons-material/Pause';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import SortIcon from '@mui/icons-material/Sort';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { PortalUiLottieLoading } from '@vs/ui/portal/lottie-loading';
import {
  IconComponent,
  ImportIcon,
  RedeemPlus,
  UnsubscribeIcon,
  UpgradeCircularIcon,
} from '@vs/ui/vs-icons';
import classNames from 'classnames';

import styles from './portal-button.module.css';

export enum ButtonType {
  inherit = 'inherit',
  default = 'default',
  neutral = 'neutral',
  primary = 'primary',
  secondary = 'secondary',
  accent = 'accent',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
  ghost = 'ghost',
  link = 'link',
}

export enum ButtonTextColor {
  none = 'none',
  default = 'default',
  neutral = 'neutral',
  primary = 'primary',
  secondary = 'secondary',
  accent = 'accent',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum ButtonVariant {
  text = 'text',
  outlined = 'outlined',
  contained = 'contained',
}

export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum ButtonIcon {
  cross = 'cross',
  logout = 'logout',
  search = 'search',
  sort = 'sort',
  delete = 'delete',
  done = 'done',
  star = 'star',
  starOutline = 'starOutline',
  expandMore = 'expandMore',
  expandLess = 'expandLess',
  moreVert = 'moreVert',
  personAdd = 'personAdd',
  save = 'save',
  arrowUpward = 'arrowUpward',
  arrowDownward = 'arrowDownward',
  arrowRight = 'arrowRight',
  adminPanelSettingsOutlined = 'adminPanelSettingsOutlined',
  domainAddOutlined = 'domainAddOutlined',
  personRemoveAlt1Outlined = 'personRemoveAlt1Outlined',
  remove = 'remove',
  add = 'add',
  play = 'play',
  pause = 'pause',
  edit = 'edit',
  send = 'send',
  visibility = 'visibility',
  borderBottom = 'borderBottom',
  borderTop = 'borderTop',
  navigateNext = 'navigateNext',
  unsubscribe = 'unsubscribe',
  upgradeCircular = 'upgradeCircular',
  mail = 'mail',
  check = 'check',
  copy = 'copy',
  redeemPlus = 'redeemPlus',
  import = 'import',
  refresh = 'refresh',
}

const iconMap: { [key in ButtonIcon]: IconComponent } = {
  cross: CloseIcon,
  logout: LogoutIcon,
  search: SearchIcon,
  sort: SortIcon,
  delete: DeleteOutlineIcon,
  done: DoneIcon,
  star: StarIcon,
  starOutline: StarOutlineIcon,
  expandMore: ExpandMoreRoundedIcon,
  expandLess: ExpandLessRoundedIcon,
  moreVert: MoreVertRoundedIcon,
  personAdd: PersonAddAltIcon,
  save: SaveAltIcon,
  arrowUpward: ArrowUpwardIcon,
  arrowDownward: ArrowDownwardIcon,
  arrowRight: KeyboardArrowRightIcon,
  adminPanelSettingsOutlined: AdminPanelSettingsOutlinedIcon,
  domainAddOutlined: DomainAddOutlinedIcon,
  personRemoveAlt1Outlined: PersonRemoveAlt1OutlinedIcon,
  remove: RemoveCircleOutlineIcon,
  add: AddIcon,
  play: PlayArrowOutlinedIcon,
  pause: PauseIcon,
  edit: EditIcon,
  send: SendIcon,
  visibility: VisibilityIcon,
  borderBottom: BorderBottomIcon,
  borderTop: BorderTopIcon,
  navigateNext: NavigateNextIcon,
  unsubscribe: UnsubscribeIcon,
  upgradeCircular: UpgradeCircularIcon,
  mail: MailOutlineOutlinedIcon,
  check: CheckIcon,
  copy: ContentCopyIcon,
  redeemPlus: RedeemPlus,
  import: ImportIcon,
  refresh: RefreshIcon,
};
type RestMuiBtnProps = Omit<
  ButtonProps,
  'type' | 'variant' | 'size' | 'startIcon' | 'endIcon'
>;
export type PortalButtonProps = {
  buttonType?: HTMLButtonElement['type'];
  type?: keyof typeof ButtonType;
  label?: string;
  isLoading?: boolean;
  variant?: keyof typeof ButtonVariant;
  size?: keyof typeof ButtonSize;
  icon?: keyof typeof ButtonIcon;
  endIcon?: keyof typeof ButtonIcon;
  width?: number | 'full';
  onlyText?: boolean;
  textColor?: keyof typeof ButtonTextColor;
  'data-testid'?: string;
} & RestMuiBtnProps;

export function PortalButton({
  buttonType,
  type = ButtonType.default,
  label,
  disabled = false,
  isLoading = false,
  variant = ButtonVariant.contained,
  size = ButtonSize.sm,
  width,
  icon,
  endIcon,
  onlyText = false,
  textColor = 'secondary',
  className = '',
  'data-testid': dataTestId,
  ...restMuiProps
}: PortalButtonProps) {
  const classesStyle = classNames(styles['overwrite-base'], {
    [styles['overwrite-ghost']]: type === ButtonType.ghost,
    [styles['overwrite-link']]: type === ButtonType.link,
    [styles['overwrite-size-xs']]: size === ButtonSize.xs,
    [styles['overwrite-size-sm']]: size === ButtonSize.sm,
    [styles['overwrite-size-md']]: size === ButtonSize.md,
    [styles['overwrite-size-lg']]: size === ButtonSize.lg,
    [`w-full`]: width === 'full',
    [styles['only-icon']]: !label && icon,
    [styles['only-text']]: onlyText,
  });
  const classesStyleOutlined = classNames({
    [styles['overwrite-default-outlined']]: type === ButtonType.default,
    [styles['overwrite-neutral-outlined']]: type === ButtonType.neutral,
    [styles['overwrite-primary-outlined']]: type === ButtonType.primary,
    [styles['overwrite-secondary-outlined']]: type === ButtonType.secondary,
    [styles['overwrite-accent-outlined']]: type === ButtonType.accent,
    [styles['overwrite-info-outlined']]: type === ButtonType.info,
    [styles['overwrite-success-outlined']]: type === ButtonType.success,
    [styles['overwrite-warning-outlined']]: type === ButtonType.warning,
    [styles['overwrite-error-outlined']]: type === ButtonType.error,
    [styles['overwrite-disabled-outlined']]:
      variant === ButtonVariant.outlined && disabled,
  });
  const classesStyleContained = classNames({
    [styles['overwrite-inherit']]: type === ButtonType.inherit,
    [styles['overwrite-default']]: type === ButtonType.default,
    [styles['overwrite-neutral']]: type === ButtonType.neutral,
    [styles['overwrite-primary']]: type === ButtonType.primary,
    [styles['overwrite-secondary']]: type === ButtonType.secondary,
    [styles['overwrite-accent']]: type === ButtonType.accent,
    [styles['overwrite-info']]: type === ButtonType.info,
    [styles['overwrite-success']]: type === ButtonType.success,
    [styles['overwrite-warning']]: type === ButtonType.warning,
    [styles['overwrite-error']]: type === ButtonType.error,
    [styles['overwrite-disabled']]:
      variant === ButtonVariant.contained && disabled,
  });
  const classesTextColor = classNames({
    [styles['overwrite-color-default']]: textColor === ButtonType.default,
    [styles['overwrite-color-neutral']]: textColor === ButtonType.neutral,
    [styles['overwrite-color-primary']]: textColor === ButtonType.primary,
    [styles['overwrite-color-secondary']]: textColor === ButtonType.secondary,
    [styles['overwrite-color-accent']]: textColor === ButtonType.accent,
    [styles['overwrite-color-info']]: textColor === ButtonType.info,
    [styles['overwrite-color-success']]: textColor === ButtonType.success,
    [styles['overwrite-color-warning']]: textColor === ButtonType.warning,
    [styles['overwrite-color-error']]: textColor === ButtonType.error,
  });

  const additonalProps: ButtonProps = {};
  if (!isLoading && icon) {
    const MappedIcon = iconMap[icon];
    additonalProps['startIcon'] = <MappedIcon />;
  }
  if (!isLoading && endIcon) {
    const MappedIcon = iconMap[endIcon];
    additonalProps['endIcon'] = <MappedIcon />;
  }
  if (isLoading) {
    additonalProps['startIcon'] = <PortalUiLottieLoading size={25} />;
  }
  if (!onlyText) {
    additonalProps['variant'] = variant;
  }

  return (
    <MuiButton
      {...restMuiProps}
      disabled={disabled}
      type={buttonType}
      style={{ width: !!width && width !== 'full' ? `${width}px` : undefined }}
      className={classNames(
        classesStyle,
        {
          [classesStyleOutlined]:
            !onlyText && variant === ButtonVariant.outlined,
          [classesStyleContained]:
            !onlyText && variant !== ButtonVariant.outlined,
          [classesTextColor]: onlyText,
        },
        className
      )}
      data-testid={dataTestId}
      {...additonalProps}
    >
      {label}
    </MuiButton>
  );
}

export default PortalButton;
