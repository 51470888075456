import { Env } from './env.type';

const vsAdminDomain = 'https://admin.viewsonic.cloud';
const vsAppDomain = 'https://cloud.viewsonic.com';
export const env: Env = {
  apiEndpoint: {
    mvb: 'https://api.myviewboard.com',
    vs: 'https://api.viewsonic.cloud',
    og: 'https://originals-api.myviewboard.com/admin/graphql',
    dmAdmin: 'https://api.myviewboard.com/api/dm/admin-portal/v2/cms/graphql',
    vsSubscription:
      'https://api.viewsonic.cloud/subscription/graphql/v1/subscription',
    vsAuth: vsAppDomain,
    vsAdminAuth: vsAdminDomain,
  },
  oidcAuthority: {
    vs: `${vsAppDomain}/auth/v1/oidc`,
    vsAdmin: `${vsAdminDomain}/auth/v1/oidc`,
    mvb: `https://api.myviewboard.com/oidc/provider`,
    newMvb: 'https://auth.dev.myviewboard.com/oidc/v1',
  },
  appUrl: {
    legalDoc: `${vsAppDomain}/legal`,
    accountPortal: `${vsAppDomain}/account`,
    entityPortal: `${vsAppDomain}/entity`,
  },
  applicationInsightsInstrumentationKey: {
    mvb: '24ce024d-7d9b-408c-929f-253d7a6bb1b4',
  },
  assets: {
    vs: 'https://assets.viewsonic.cloud',
  },
};
