import { type BrowserOptions, init, replayIntegration } from '@sentry/react';
import { reactRouterV6BrowserTracingIntegration } from '@vs/sentry-react-router-integration';
import { type DeployEnv } from '@vs/utils/envConfig';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const isLocalDevMode =
  import.meta.hot !== undefined && import.meta.env.MODE === 'development';
const deployEnv: DeployEnv = import.meta.env.VITE_DEPLOY_ENV;

export function initSentry(options?: BrowserOptions) {
  if (!isLocalDevMode || options?.debug) {
    init({
      dsn: import.meta.env.VITE_SENTRY_PROJECT_DSN,
      environment: deployEnv,
      integrations: [
        // Currently not compatible with component tracking
        // https://docs.sentry.io/platforms/javascript/guides/react/features/component-monitoring/
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          stripBasename: false,
        }),
        replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: deployEnv === 'prod' ? 0.1 : 1.0,
      // Session Replay
      // replaysSessionSampleRate: deployEnv === 'prod' ? 0.1 : 1.0,
      // replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      ...options,
    });
  }
}
