import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { GraphQLError, GraphQLErrorExtensions } from 'graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

export type RtkErrorResponseOfGraphQL = {
  status: number;
  data: SerializedGraphQLError[];
};

export type SerializedGraphQLError = {
  message: string;
  path: Maybe<ReadonlyArray<string | number>>;
  extensions?: Maybe<GraphQLErrorExtensions>
}

export function isGraphQLRequestBaseQueryError(error: unknown): error is ErrorResponse {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    'message' in error &&
    'stack' in error
  );
}

export function isRtkErrorResponseOfGraphQL(
  error: unknown
): error is RtkErrorResponseOfGraphQL {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    'data' in error
  );
}

export function parseGraphQLRequestBaseQueryError(error: ErrorResponse): GraphQLError[] {
  try {
    const errorMessage = error.message;
    const graphqlErrorMatch = errorMessage.match(/\{.*\}/);
    if (graphqlErrorMatch) {
      const parsedError = JSON.parse(graphqlErrorMatch[0]);
      return parsedError.response.errors.map((err: SerializedGraphQLError) =>
        new GraphQLError(
          err.message,
          undefined,
          undefined,
          undefined,
          err.path,
          error,
          err.extensions
        )
      );
    }
    return [new GraphQLError('Unknown error')];
  } catch (e) {
    console.error('Error parsing GraphQL error:', e);
    return [new GraphQLError('Unknown error')];
  }
}

export function isDMAdminApi(url: string): boolean {
  return url.includes('dm/admin-portal');
}
