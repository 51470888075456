import { DefinitionsFromApi, OverrideResultType } from '@reduxjs/toolkit/query';
import { SubscriptionStatus } from '@vs/types';

import { transformAdminProductFamily } from './transform/adminProductFamily';
import { transformChargifyPrice } from './transform/chargifyPrice';
import { transformProductFamilies } from './transform/productFamilies';
import { transformProductFamilyList } from './transform/productFamilyList';
import { api as vsSubscriptionApiInjected } from './vsSubscriptionApiInjected';

export enum VsSubscriptionSliceTags {
  USER_SUBSCRIPTION = 'USER_SUBSCRIPTION',
  USER_CHARGIFY_INFO = 'USER_CHARGIFY_INFO',
  PAYMENT_PROFILE = 'PAYMENT_PROFILE',
}

type Definitions = DefinitionsFromApi<typeof vsSubscriptionApiInjected>;

type EnhancedDefinitions = Omit<
  Definitions,
  | 'AdminProductFamilies'
  | 'ProductFamiliesList'
  | 'ProductFamilies'
  | 'ChargifyPrice'
> & {
  AdminProductFamilies: OverrideResultType<
    Definitions['AdminProductFamilies'],
    ReturnType<typeof transformAdminProductFamily>
  >;
  ProductFamiliesList: OverrideResultType<
    Definitions['ProductFamiliesList'],
    ReturnType<typeof transformProductFamilyList>
  >;
  ProductFamilies: OverrideResultType<
    Definitions['ProductFamilies'],
    ReturnType<typeof transformProductFamilies>
  >;
  ChargifyPrice: OverrideResultType<
    Definitions['ChargifyPrice'],
    ReturnType<typeof transformChargifyPrice>
  >;
};

const enhancedRtkApi = vsSubscriptionApiInjected.enhanceEndpoints<
  string,
  EnhancedDefinitions
>({
  endpoints: {
    ChargifyPrice: {
      transformResponse: transformChargifyPrice,
    },
    ProductFamilies: {
      transformResponse: transformProductFamilies,
    },
    ProductFamiliesList: {
      transformResponse: transformProductFamilyList,
    },
    AdminProductFamilies: {
      transformResponse: transformAdminProductFamily,
    },
    AdminCreateProductFamilies: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: { createProductFamily: cpf },
        } = await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              draft.push({
                id: cpf.id,
                name: cpf.name,
                description: cpf.description,
                display_icon_url: cpf.display_icon_url,
                create_time: cpf.create_time,
                modify_time: cpf.modify_time,
                products: [],
                components: [],
              });
            }
          )
        );
      },
    },
    AdminUpdateProductFamily: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft.find(pf => pf.id === arg.productFamilyId);
              if (target) {
                Object.assign(target, {
                  name: arg.name ?? '',
                  description: arg.description,
                  display_icon_url: arg.displayIconUrl,
                });
              }
            }
          )
        );
      },
    },
    AdminDeleteProductFamilies: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const targetIndex = draft.findIndex(
                pf => pf.id === arg.productFamilyId
              );
              if (targetIndex >= 0) {
                draft.splice(targetIndex, 1);
              }
            }
          )
        );
      },
    },
    AdminCreateProduct: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: {
            createProduct: { __typename, ...created },
          },
        } = await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const productFamily = draft.find(
                pf => pf.id === arg.productFamilyId
              );
              if (productFamily) {
                productFamily.products.push({
                  ...created,
                  plans: [],
                });
              }
            }
          )
        );
      },
    },
    AdminUpdateProduct: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(pf => pf.products)
                .flat()
                .find(p => p.id === arg.productId);
              if (target) {
                Object.assign(target, {
                  name: arg.name,
                  description: arg.description,
                  applicable_type: arg.applicableType,
                  allowed_payment_source: arg.allowedPaymentSource,
                  is_publish: arg.isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminToggleProductPublish: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(pf => pf.products)
                .flat()
                .find(p => p.id === arg.productId);
              if (target) {
                const { isPublish } = arg;
                Object.assign(target, {
                  is_publish: isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminDeleteProduct: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const targetProductFamily = draft.find(
                pf =>
                  pf.products.find(p => p.id === arg.productId) !== undefined
              );
              if (targetProductFamily) {
                const targetIndex = targetProductFamily.products.findIndex(
                  p => p.id === arg.productId
                );
                if (targetIndex >= 0) {
                  targetProductFamily.products.splice(targetIndex, 1);
                }
              }
            }
          )
        );
      },
    },
    AdminCreateProductPlan: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: {
            createProductPlan: { __typename, ...created },
          },
        } = await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const product = draft
                .map(pf => pf.products)
                .flat()
                .find(p => p.id === arg.productId);
              if (product) {
                product.plans.push(created);
              }
            }
          )
        );
      },
    },
    AdminUpdateProductPlan: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(pf => pf.products)
                .flat()
                .map(p => p.plans)
                .flat()
                .find(pp => pp.id === arg.productPlanId);
              if (target) {
                Object.assign(target, {
                  name: arg.name,
                  description: arg.description,
                  duration: arg.duration,
                  duration_unit: arg.durationUnit,
                  trial_duration: arg.trialDuration,
                  trial_duration_unit: arg.trialDurationUnit,
                  is_auto_approve: arg.isAutoApprove,
                  is_notifiable: arg.isNotifiable,
                  is_default: arg.isDefault,
                  is_publish: arg.isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminToggleProductPlanPublish: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(pf => pf.products)
                .flat()
                .map(p => p.plans)
                .flat()
                .find(pp => pp.id === arg.productPlanId);
              if (target) {
                Object.assign(target, {
                  is_publish: arg.isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminDeleteProductPlan: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const product = draft
                .map(pf => pf.products)
                .flat()
                .find(
                  p =>
                    p.plans.find(
                      pp => pp.id === arg.deleteProductPlanProductPlanId2
                    ) !== undefined
                );
              if (product) {
                const targetIndex = product.plans.findIndex(
                  pp => pp.id === arg.deleteProductPlanProductPlanId2
                );
                if (targetIndex >= 0) {
                  product.plans.splice(targetIndex, 1);
                }
              }
            }
          )
        );
      },
    },
    AdminCreateComponent: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: {
            createComponent: { __typename, ...created },
          },
        } = await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const productFamily = draft.find(
                pf => pf.id === arg.productFamilyId
              );
              if (productFamily) {
                productFamily.components.push({
                  ...created,
                  plans: [],
                });
              }
            }
          )
        );
      },
    },
    AdminUpdateComponent: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(p => p.components)
                .flat()
                .find(c => c.id === arg.componentId);
              if (target) {
                Object.assign(target, {
                  name: arg.name,
                  description: arg.description,
                  applicable_type: arg.applicableType,
                  allowed_payment_source: arg.allowedPaymentSource,
                  is_publish: arg.isPublish,
                  unit_name: arg.unitName,
                });
              }
            }
          )
        );
      },
    },
    AdminToggleComponentPublish: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(p => p.components)
                .flat()
                .find(c => c.id === arg.componentId);
              if (target) {
                const { isPublish } = arg;
                Object.assign(target, {
                  is_publish: isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminDeleteComponent: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const targetProductFamily = draft.find(
                pf =>
                  pf.components.find(c => c.id === arg.componentId) !==
                  undefined
              );
              if (targetProductFamily) {
                const targetIndex = targetProductFamily.components.findIndex(
                  p => p.id === arg.componentId
                );
                if (targetIndex >= 0) {
                  targetProductFamily.components.splice(targetIndex, 1);
                }
              }
            }
          )
        );
      },
    },
    AdminCreateComponentPlan: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const {
          data: {
            createComponentPlan: { __typename, ...created },
          },
        } = await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const component = draft
                .map(pf => pf.components)
                .flat()
                .find(c => c.id === arg.componentId);
              if (component) {
                component.plans.push(created);
              }
            }
          )
        );
      },
    },
    AdminUpdateComponentPlan: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(pf => pf.components)
                .flat()
                .map(c => c.plans)
                .flat()
                .find(cp => cp.id === arg.componentPlanId);
              if (target) {
                Object.assign(target, {
                  name: arg.name,
                  description: arg.description,
                  basic_quantity: arg.basicQuantity,
                  duration: arg.duration,
                  duration_unit: arg.durationUnit,
                  trial_duration: arg.trialDuration,
                  trial_duration_unit: arg.trialDurationUnit,
                  is_auto_approve: arg.isAutoApprove,
                  is_notifiable: arg.isNotifiable,
                  is_default: arg.isDefault,
                  is_publish: arg.isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminToggleComponentPlanPublish: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const target = draft
                .map(pf => pf.components)
                .flat()
                .map(c => c.plans)
                .flat()
                .find(cp => cp.id === arg.componentPlanId);
              if (target) {
                Object.assign(target, {
                  is_publish: arg.isPublish,
                });
              }
            }
          )
        );
      },
    },
    AdminDeleteComponentPlan: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'AdminProductFamilies',
            undefined,
            draft => {
              const component = draft
                .map(pf => pf.components)
                .flat()
                .find(
                  c =>
                    c.plans.find(cp => cp.id === arg.componentPlanId) !==
                    undefined
                );
              if (component) {
                const targetIndex = component.plans.findIndex(
                  cp => cp.id === arg.componentPlanId
                );
                if (targetIndex >= 0) {
                  component.plans.splice(targetIndex, 1);
                }
              }
            }
          )
        );
      },
    },
    PaymentProfile: {
      providesTags: [VsSubscriptionSliceTags.PAYMENT_PROFILE],
    },
    ChargifyCreatePaymentProfile: {
      invalidatesTags: [VsSubscriptionSliceTags.PAYMENT_PROFILE],
    },
    ChargifyUpdatePaymentProfile: {
      invalidatesTags: [VsSubscriptionSliceTags.PAYMENT_PROFILE],
    },
    ChargifyDeletePaymentProfile: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'PaymentProfile',
            undefined,
            draft => {
              const targetIndex = draft.chargify_payment_profile.findIndex(
                p => p.id === arg.chargifyDeletePaymentProfileId
              );
              if (targetIndex !== -1) {
                draft.chargify_payment_profile.splice(targetIndex, 1);
              }
            }
          )
        );
      },
    },
    UserSubscriptions: {
      providesTags: [VsSubscriptionSliceTags.USER_SUBSCRIPTION],
    },
    UserSubscriptionsRealTimeData: {
      providesTags: [VsSubscriptionSliceTags.USER_CHARGIFY_INFO],
    },
    GoTrail: {
      invalidatesTags: [VsSubscriptionSliceTags.USER_SUBSCRIPTION],
    },
    ChargifySubscribe: {
      invalidatesTags: [
        VsSubscriptionSliceTags.USER_SUBSCRIPTION,
        VsSubscriptionSliceTags.USER_CHARGIFY_INFO,
        VsSubscriptionSliceTags.PAYMENT_PROFILE,
      ],
    },
    ChargifyResume: {
      invalidatesTags: [VsSubscriptionSliceTags.USER_CHARGIFY_INFO],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'UserSubscriptions',
            undefined,
            draft => {
              const target = draft.userSubscriptions.find(
                sub => sub.id === arg.subscriptionId
              );
              if (target) {
                if (
                  target.status === SubscriptionStatus.TRIAL_PENDING_CANCELLED
                ) {
                  target.status = SubscriptionStatus.TRIALING;
                } else if (
                  target.status === SubscriptionStatus.PENDING_CANCELLED ||
                  target.status === SubscriptionStatus.TRIAL_ENDED
                ) {
                  target.status = SubscriptionStatus.ACTIVE;
                }
              }
            }
          )
        );
      },
    },
    ChargifyMigrate: {
      invalidatesTags: [VsSubscriptionSliceTags.USER_SUBSCRIPTION],
    },
    Unsubscribe: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'UserSubscriptions',
            undefined,
            draft => {
              draft.userSubscriptions.forEach(sub => {
                if (arg.subscriptionIds.includes(sub.id)) {
                  if (sub.status === SubscriptionStatus.TRIALING) {
                    sub.status = SubscriptionStatus.TRIAL_PENDING_CANCELLED;
                  } else if (sub.status === SubscriptionStatus.ACTIVE) {
                    sub.status = SubscriptionStatus.PENDING_CANCELLED;
                  }
                }
              });
            }
          )
        );
      },
    },
    ChargifyAddCoupon: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'UserSubscriptionsRealTimeData',
            undefined,
            draft => {
              const target = draft.userSubscriptions.find(
                sub => sub.id === arg.subscriptionId
              );
              if (target && target.vendorRealTimeData != null) {
                target.vendorRealTimeData.couponCodeApplied = Array.isArray(
                  arg.couponCodes
                )
                  ? arg.couponCodes[0]
                  : arg.couponCodes;
                target.vendorRealTimeData.couponUseCount = 0;
                target.vendorRealTimeData.couponUsesAllowed = 1;
              }
            }
          )
        );
      },
    },
    ChargifyRemoveCoupon: {
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          enhancedRtkApi.util.updateQueryData(
            'UserSubscriptionsRealTimeData',
            undefined,
            draft => {
              const target = draft.userSubscriptions.find(
                sub => sub.id === arg.subscriptionId
              );
              if (target && target.vendorRealTimeData != null) {
                target.vendorRealTimeData.couponCodeApplied = '';
                target.vendorRealTimeData.couponUseCount = 0;
                target.vendorRealTimeData.couponUsesAllowed = 0;
              }
            }
          )
        );
      },
    },
  },
});

export { enhancedRtkApi as vsSubscriptionApiSlice };
export * from './vsSubscriptionApiInjected';

/* If you transform response to another type,
   you have to re-export it's hook from enhanced slice to get correct type definition
   (No related issues found on github) */
export const {
  useAdminProductFamiliesQuery: useTransformAdminProductFamiliesQuery,
  useProductFamiliesListQuery: useTransformProductFamiliesListQuery,
  useProductFamiliesQuery: useTransformProductFamiliesQuery,
  useChargifyPriceQuery: useTransformChargifyPriceQuery,
} = enhancedRtkApi;
